import React from 'react';

export default function ErrorIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.99129 7.62352C5.80155 4.42032 6.70667 2.81871 7.94873 2.40643C8.63198 2.17963 9.36803 2.17963 10.0513 2.40643C11.2934 2.81871 12.1985 4.42032 14.0087 7.62352C15.819 10.8267 16.7241 12.4283 16.4526 13.7333C16.3032 14.4513 15.9352 15.1024 15.4013 15.5936C14.4308 16.4863 12.6206 16.4863 9 16.4863C5.37949 16.4863 3.56923 16.4863 2.59872 15.5936C2.06481 15.1024 1.6968 14.4513 1.54742 13.7333C1.2759 12.4283 2.18103 10.8267 3.99129 7.62352Z"
                fill="currentColor"
            />
            <path d="M8.99414 12.3613H9.00089" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 10.1114V7.11133" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
