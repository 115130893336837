import ErrorIcon from '@app/components/icons/error';
import { useModal } from '@app/components/modal-views/context';
import { Button } from '@app/shadcn/components/ui/button';
import { useToast } from '@app/shadcn/hooks/use-toast';
import { cn } from '@app/shadcn/util/lib';
import { useCustomizeFormLinkMutation, useLinkExistMutation, useUpdateCustomizeLinkMutation } from '@app/store/formUrl/api';
import { LinkDetails, LinkExistRequestBody } from '@app/store/formUrl/types';
import { isValidRelativeURL } from '@app/utils/validationUtils';
import { debounce, isEmpty } from 'lodash';
import { Check } from 'lucide-react';
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';

const CustomizeFormLinkComponent = ({
    fingerPrint,
    destinationUrl,
    buttonText = 'Customize Link',
    isInvalidUrl = false,
    openModalOnSuccess = false,
    formId = ''
}: {
    fingerPrint: string | null;
    buttonText?: string;
    destinationUrl: string | null;
    isInvalidUrl?: boolean;
    openModalOnSuccess?: boolean;
    formId?: string;
}) => {
    const [protocol, setProtocol] = useState<string>('https:');
    const [mappedDomain, setMappedDomain] = useState<string>('');
    const [mappedPath, setMappedPath] = useState<string | null>(null);
    const [isLinkError, setLinkError] = useState(false);
    const [linkMessage, setLinkMessage] = useState('');

    const { toast } = useToast();

    const { openModal } = useModal();

    const [linkExist] = useLinkExistMutation();

    const [customizeLink, { isLoading }] = useCustomizeFormLinkMutation();

    const [updateLink] = useUpdateCustomizeLinkMutation();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setMappedDomain(window.location.host);
            setProtocol(window.location.protocol);
        }
    }, []);

    const handleMappedPathOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!isValidRelativeURL(e.target.value)) {
            setLinkMessage("Following characters are not supported on link: space, '/'");
            setLinkError(true);
            setMappedPath(e.target.value);
            return;
        }

        const linkExistBody: LinkExistRequestBody = {
            mappedDomain: window.location.host,
            mappedPath: e.target.value
        };
        const response = await linkExist(linkExistBody);

        if ('error' in response) {
            // url not found
            setLinkError(false);
            setLinkMessage('This URL is available');
        } else {
            // url already exist
            setLinkError(true);
            setLinkMessage('This URL link is not available.');
        }
        setMappedPath(e.target.value);
    };

    const debouncedMappedPath = useMemo(() => {
        return debounce(handleMappedPathOnChange, 500);
    }, []);

    const handleOnFormSubmit = async (e: FormEvent<HTMLElement>) => {
        e.preventDefault();
        if (destinationUrl && mappedPath) {
            const customizeLinkBody: LinkDetails = {
                destinationUrl,
                mappedDomain,
                mappedPath,
                browserFingerprint: fingerPrint
            };
            let response;
            if (formId) {
                response = await updateLink({ form_id: formId, linkBody: customizeLinkBody as LinkDetails });
            } else {
                response = await customizeLink(customizeLinkBody);
            }
            if ('data' in response) {
                if (openModalOnSuccess) openModal('SUCCESS_MODAL', { mappedUrl: `https://${mappedDomain}/to/${mappedPath}` });
                else {
                    toast({
                        title: 'Link Customized Successfully.',
                        description: `${protocol}//${mappedDomain}/to/${mappedPath}`
                    });
                }
            } else {
                toast({ title: 'Fail to customize link', variant: 'destructive' });
            }
        }
    };
    return (
        <>
            <div id="customize link" className="flex w-full flex-col gap-2">
                <p className="body-16-medium">Customize form link</p>
                <div className="flex">
                    <div className="flex h-12 w-1/3 flex-row items-center gap-8 rounded-xl rounded-r-none border border-gray-200 bg-gray-50 py-[14px] px-2 md:max-w-full md:px-3">
                        <p className="body-16-regular text-gray-500 truncate">
                            {protocol}
                            {'//'}
                            {mappedDomain}/to/
                        </p>
                    </div>
                    <input onChange={debouncedMappedPath} placeholder="my-awesome-form" className="h-12 w-full rounded-xl rounded-l-none border border-l-0 bg-white py-[14px] px-3 text-gray-900 outline-none md:pr-8" type="text" />
                </div>
                {mappedPath && !isEmpty(mappedPath) && (
                    <div className="body-14-medium mt-2 flex items-center gap-2">
                        {isLinkError ? <ErrorIcon className="h-[18px] w-[18px] text-error-600" /> : <Check className="h-[18px] w-[18px] text-success-600" />}
                        <p className={cn('', isLinkError ? 'text-error-600' : 'text-success-600')}>{linkMessage}</p>
                    </div>
                )}
            </div>
            {formId && (
                <div className="flex items-center gap-1 text-xs text-orange-500 md:gap-0 md:text-sm">
                    <div className="flex">
                        <ErrorIcon className="h-5 w-5" />
                    </div>
                    <h1>Changing the link will cause previous analytics data to disappear.</h1>
                </div>
            )}
            <Button className="w-fit" onClick={handleOnFormSubmit} isLoading={isLoading} disabled={isInvalidUrl || isLinkError || isEmpty(mappedPath) || isEmpty(destinationUrl)}>
                {buttonText || 'Customize Link'}
            </Button>
        </>
    );
};

export default CustomizeFormLinkComponent;
