import AnchorLink from '@app/components/common/links/anchor-link';
import { EmptyPreviewForm } from '@app/components/icons/empty-preview-form';
import { Button } from '@app/shadcn/components/ui/button';
import { ScrollArea } from '@app/shadcn/components/ui/scroll-area';
import { cn } from '@app/shadcn/util/lib';
import { LinkIcon } from 'lucide-react';
import { useState } from 'react';

export const FormPreview = ({ formLink, className }: { formLink?: string | null; className?: string }) => {
    const [iframeStatus, setIframeStatus] = useState<'loading' | 'error' | 'loaded'>('loading');

    const handleIframeLoad = () => {
        setIframeStatus('loaded');
    };

    const handleIframeError = () => {
        setIframeStatus('error');
    };

    function getIFrameUrlLink(link: string) {
        let newLink = link;
        if (newLink.includes('usp=sf_link')) {
            newLink = newLink.split('?')[0];
        }
        if (newLink.includes('?')) {
            newLink = newLink + '&embedded=true';
        } else {
            newLink = newLink + '?embedded=true';
        }
        return newLink;
    }

    return (
        <div className={cn('flex h-full w-full flex-col rounded-xl border border-gray-200 lg:w-2/5', className)}>
            <div id="original-form-preview" className={cn('flex h-[320px] w-full flex-col gap-2 bg-secondary-grey-gradient', !formLink ? 'overflow-hidden px-[50px] pt-6' : 'items-center justify-center')}>
                {formLink ? (
                    <div className="flex h-full w-full flex-col gap-4 px-4 pt-6 lg:px-10">
                        <h1 className="body-18-bold text-center">Preview</h1>
                        <ScrollArea>
                            <iframe
                                title="Form Preview"
                                className={`h-[320px] w-full ${iframeStatus === 'loading' ? 'hidden' : ''}`}
                                onErrorCapture={handleIframeError}
                                onError={handleIframeError}
                                onLoad={handleIframeLoad}
                                src={getIFrameUrlLink(formLink)}
                            ></iframe>
                        </ScrollArea>
                    </div>
                ) : (
                    <div className="flex h-full w-full flex-col items-center justify-center gap-4 px-4 pt-6 lg:px-10">
                        <EmptyPreviewForm />
                        <h1 className="body-14-medium">Enter a link to generate a preview</h1>
                    </div>
                )}
            </div>
            <div id="original-form-link" className="flex h-[68px] items-center justify-center border-t border-gray-200 bg-gray-50">
                {formLink ? (
                    <AnchorLink href={formLink} target="_blank">
                        <Button size={'sm'} variant={'secondary-grey'} postIcon={<LinkIcon />}>
                            Goto Form
                        </Button>
                    </AnchorLink>
                ) : (
                    <div className="h-5 w-1/3 rounded-xl bg-gray-200"></div>
                )}
            </div>
        </div>
    );
};
