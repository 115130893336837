import TextInput from '@app/components/common/input/TextInput';
import CopyIcon from '@app/components/icons/copy-icon';
import ErrorIcon from '@app/components/icons/error';
import InfoIcon from '@app/components/icons/info';
import CustomizeFormLinkComponent from '@app/components/pages/common/CustomizeFormLink';
import { FormPreview } from '@app/components/pages/createLink/FormPreview';
import { useCopyToClipBoard } from '@app/lib/hooks/use-copy-to-clipboard';
import { useToast } from '@app/shadcn/hooks/use-toast';
import { cn } from '@app/shadcn/util/lib';
import { useGetUserStatusQuery } from '@app/store/users/api';
import { isValidFormUrl } from '@app/utils/validationUtils';
import { ChangeEvent, useEffect, useState } from 'react';
import { useModal } from '../../modal-views/context';

interface CustomizeUrlFormProps {
    fingerprint: string;
    destinationFormUrl?: string;
    formId?: string;
    className?: string;
}

export default function CustomizeUrlForm({ fingerprint, destinationFormUrl, formId = '', className }: CustomizeUrlFormProps) {
    const [isInvalidUrl, setInvalidUrl] = useState(false);
    const [fingerPrint, setFingerPrint] = useState<string | null>(fingerprint);
    const [destinationUrl, setDestinationUrl] = useState<string | null>(null);
    const [formUrlValue, setFormUrlValue] = useState('');

    const { openModal } = useModal();

    const { data: user, isError } = useGetUserStatusQuery();

    const { copyToClipboard } = useCopyToClipBoard();

    const { toast } = useToast();

    useEffect(() => {
        if (destinationFormUrl) {
            setDestinationUrl(destinationFormUrl);
            setFormUrlValue(destinationFormUrl);
        }
    }, [destinationFormUrl]);

    useEffect(() => {
        if (user) {
            setFingerPrint(null);
        } else {
            setFingerPrint(fingerprint);
        }
    }, [user, isError]);

    const handleDestinationUrlOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormUrlValue(e.target.value);
        if (!e.target.value) {
            setDestinationUrl(null);
            return;
        }
        if (!isValidFormUrl(e.target.value)) {
            setInvalidUrl(true);
            return;
        }
        setDestinationUrl(e.target.value);
        setInvalidUrl(false);
    };

    const handleClickCopyDestinationUrl = () => {
        destinationUrl &&
            copyToClipboard(destinationUrl).then(() => {
                toast({ title: 'Destination Form URL Copied' });
            });
    };

    return (
        <div className={cn('flex w-full flex-col gap-6 rounded-2xl border border-gray-200 bg-white p-5 shadow-lighterShadow lg:flex-row', className)}>
            <form id="form" className="flex w-full flex-col items-start gap-4 lg:w-3/5">
                <div className="flex w-full flex-col gap-2" id="form link ">
                    <div className="flex w-full items-center gap-2">
                        <p className="body-16-medium">Form link</p>
                        <InfoIcon className="cursor-pointer  h-4 w-4" onClick={() => openModal('COPY_GOOGLE_FORM_URL_DEMO')} />
                    </div>
                    <div className="flex w-full flex-row items-center gap-2">
                        <TextInput
                            className="w-full"
                            variant={isInvalidUrl ? 'error' : 'default'}
                            onChange={handleDestinationUrlOnChange}
                            value={formUrlValue}
                            type="text"
                            placeholder="https://docs.google.com/forms/d/e/1FAIpQL2DbXdhqfI-w/viewform?usp=sf_link"
                        />
                        <CopyIcon className="h-5 w-5 cursor-custom" onClick={handleClickCopyDestinationUrl} />
                    </div>

                    {isInvalidUrl && (
                        <div className="items-center- flex gap-2">
                            <ErrorIcon className="text-error-600" />
                            <p className="body3-responsive flex gap-1 text-xs font-medium text-error-600 md:text-sm xl:text-base">
                                Invalid URL. Please enter a valid Form URL.
                                <span onClick={() => openModal('COPY_GOOGLE_FORM_URL_DEMO')} className="cursor-pointer text-secondary-500">
                                    Learn how to find correct Google link.
                                </span>
                            </p>
                        </div>
                    )}
                </div>
                <CustomizeFormLinkComponent buttonText={formId ? 'Save Changes' : ''} openModalOnSuccess={!(destinationUrl && formId)} fingerPrint={fingerPrint} formId={formId} destinationUrl={destinationUrl} />
            </form>
            <FormPreview formLink={destinationUrl} />
        </div>
    );
}
