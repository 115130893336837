import React from 'react';

export function EmptyPreviewForm(props?: React.SVGAttributes<{}>) {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M27.832 6.80176H20.1654C12.9372 6.80176 9.32307 6.80176 7.07754 8.95745C4.83203 11.1132 4.83203 14.5827 4.83203 21.5218V25.2018C4.83203 32.1408 4.83203 35.6105 7.07754 37.766C9.32307 39.9218 12.9372 39.9218 20.1654 39.9218H27.832C35.0602 39.9218 38.6744 39.9218 40.9198 37.766C43.1654 35.6105 43.1654 32.1408 43.1654 25.2018V21.5218C43.1654 14.5827 43.1654 11.1132 40.9198 8.95745C38.6744 6.80176 35.0602 6.80176 27.832 6.80176Z"
                stroke="url(#paint0_linear_8203_23054)"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g filter="url(#filter0_d_8203_23054)">
                <path
                    d="M27.832 6.80176H20.1654C12.9372 6.80176 9.32307 6.80176 7.07754 8.95745C4.83203 11.1132 4.83203 14.5827 4.83203 21.5218V25.2018C4.83203 32.1408 4.83203 35.6105 7.07754 37.766C9.32307 39.9218 12.9372 39.9218 20.1654 39.9218H27.832C35.0602 39.9218 38.6744 39.9218 40.9198 37.766C43.1654 35.6105 43.1654 32.1408 43.1654 25.2018V21.5218C43.1654 14.5827 43.1654 11.1132 40.9198 8.95745C38.6744 6.80176 35.0602 6.80176 27.832 6.80176Z"
                    fill="url(#paint1_linear_8203_23054)"
                />
                <path
                    d="M27.832 6.80176H20.1654C12.9372 6.80176 9.32307 6.80176 7.07754 8.95745C4.83203 11.1132 4.83203 14.5827 4.83203 21.5218V25.2018C4.83203 32.1408 4.83203 35.6105 7.07754 37.766C9.32307 39.9218 12.9372 39.9218 20.1654 39.9218H27.832C35.0602 39.9218 38.6744 39.9218 40.9198 37.766C43.1654 35.6105 43.1654 32.1408 43.1654 25.2018V21.5218C43.1654 14.5827 43.1654 11.1132 40.9198 8.95745C38.6744 6.80176 35.0602 6.80176 27.832 6.80176Z"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g filter="url(#filter1_d_8203_23054)">
                <path d="M34.3613 19.4047C36.3472 19.4047 37.957 17.8593 37.957 15.9529C37.957 14.0464 36.3472 12.501 34.3613 12.501C32.3755 12.501 30.7656 14.0464 30.7656 15.9529C30.7656 17.8593 32.3755 19.4047 34.3613 19.4047Z" fill="white" />
            </g>
            <path opacity="0.7" d="M23.2621 32.2217C22.7081 33.3297 23.5138 34.6334 24.7526 34.6334H32.6917C33.9306 34.6334 34.7363 33.3297 34.1823 32.2217L30.2127 24.2826C29.5986 23.0542 27.8458 23.0542 27.2316 24.2826L23.2621 32.2217Z" fill="white" />
            <g filter="url(#filter2_d_8203_23054)">
                <path d="M10.924 31.9606C10.3097 33.1891 11.2031 34.6347 12.5768 34.6347H25.0755C26.4491 34.6347 27.3425 33.1891 26.7282 31.9606L20.4788 19.4619C19.7979 18.0999 17.8543 18.0999 17.1733 19.4619L10.924 31.9606Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_8203_23054" x="0.269531" y="5.11426" width="47.459" height="42.2451" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.875" />
                    <feGaussianBlur stdDeviation="2.15625" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.207843 0 0 0 0 0.309804 0 0 0 0.14 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8203_23054" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8203_23054" result="shape" />
                </filter>
                <filter id="filter1_d_8203_23054" x="27.1886" y="11.3086" width="14.3455" height="14.0574" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.38468" />
                    <feGaussianBlur stdDeviation="1.78851" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.207843 0 0 0 0 0.309804 0 0 0 0.14 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8203_23054" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8203_23054" result="shape" />
                </filter>
                <filter id="filter2_d_8203_23054" x="10.7266" y="14.4404" width="24.1992" height="24.1943" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.207843 0 0 0 0 0.309804 0 0 0 0.04 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8203_23054" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8203_23054" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_8203_23054" x1="23.9987" y1="6.80176" x2="23.9987" y2="39.9218" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EDF0F5" />
                    <stop offset="1" stop-color="#E4E8EF" />
                </linearGradient>
                <linearGradient id="paint1_linear_8203_23054" x1="23.9987" y1="6.80176" x2="23.9987" y2="39.9218" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F6F8FB" />
                    <stop offset="1" stop-color="#E1E5EB" />
                </linearGradient>
            </defs>
        </svg>
    );
}
